import React from 'react';
import { Link } from 'gatsby';
import { window } from 'browser-monads';
import './nav.scss';

const Nav = () => (
  <div className="header">
    <div className="header__logo-container">
      <Link to="/" className="header__initials-link">
        <p className="header__initials">RL</p>
      </Link>
      <h4>
        <Link to="/" className="header__initials-name">
          Robert Lester
        </Link>
      </h4>
    </div>
    <div className="header__link-container">
      <h4>
        <Link to="/about" className={window.location.href.indexOf('about') > 0 ? 'header__link header__active' : 'header__link'}>
          About
        </Link>
      </h4>
      <h4>
        <Link to="/design" className={window.location.href.indexOf('design') > 0 ? 'header__link header__active' : 'header__link'}>
          Design
        </Link>
      </h4>
      <h4>
        <Link to="/code" className={window.location.href.indexOf('code') > 0 ? 'header__link header__active' : 'header__link'}>
          Code
        </Link>
      </h4>
      <h4>
        <Link to="/contact" className={window.location.href.indexOf('contact') > 0 ? 'header__link header__active' : 'header__link-end'}>
          Contact
        </Link>
      </h4>
    </div>
  </div>
)

export default Nav